<template>
  <div>
    <el-tabs>
      <el-tab-pane :label="$t('m.myzhengshu')">
        <el-button type="primary" v-if="project_code !== '2024IPsyO'" @click="exportPDF('showTotal')" size="medium" style="margin:5px;">{{$t('m.mydaochupdf')}}</el-button>
        <el-button type="primary" v-if="project_code == '2024IPsyO'" @click="exportPDF('showIpsyoTotal')" size="medium" style="margin:5px;">{{$t('m.mydaochupdf')}}</el-button>


        <div id="showTotal" v-if="project_code == '21-22FBLC_Final'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer">
            <!-- <img v-if="item.real_rank>30" src="../../assets/cert_30.jpg" class="model"/>
            <img v-if="item.real_rank <= 30 && item.real_rank>20" src="../../assets/cert_30.jpg" class="model"/>
            <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../assets/cert_20.jpg" class="model"/>
            <img v-if="item.real_rank <= 10" src="../../assets/cert_10.jpg" class="model"/>
            <div class="event">{{item.application_event}}</div>
            <div class="award" v-if="item.award_lable_type == 'rank'">National {{`${item.real_rank}${rank(item.real_rank)}`}} Place</div>
            <div class="award" v-if="item.award_lable_type == 'percent'">National {{item.award_lable}}</div>

            <div class="award" v-if="item.award_lable_type == 'grade'">{{item.award_lable}}</div>

            <div class="student">
                <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school">{{item.account.account_nameEN}}</div> -->

            <img
                src="../../assets/cert_cec.jpg"
                class="model"
            />
            <!-- <img
            v-if="item.real_rank <= 30 && item.real_rank > 20"
            src="../../assets/cert_30.jpg"
            class="model"
            />
            <img
            v-if="item.real_rank <= 20 && item.real_rank > 10"
            src="../../assets/cert_20.jpg"
            class="model"
            />
            <img
            v-if="item.real_rank <= 10"
            src="../../assets/cert_10.jpg"
            class="model"
            /> -->
            <div class="ziZm">兹证明</div>


            <div class="event">{{ item.project_name }}</div>

            <div class="projectName">{{ item.application_event }}</div>

            <div class="award">{{ item.award_show_lable }}</div>

            <div class="awardPh">{{'('+ item.percent_lable + ')'}}</div>

            <div class="student">
              <span class="name">{{ item.student.student_lastName + item.student.student_givenName }}</span>
            </div>
            <div class="school">{{ item.account.account_name }}</div>

            <div class="email">{{ item.student.student_email }}</div>

            <div class="rhZm">荣获</div>

            <img class="saiquImg" v-if="item.group_name == '92赛区'" src="../../assets/fblc-92赛区.jpg">
            <img class="saiquImg" v-if="item.group_name == 'FE赛区'" src="../../assets/fblc-FE赛区.jpg">
            <img class="saiquImg" v-if="item.group_name == '标准赛区'" src="../../assets/fblc-标准赛区.jpg">
            <img class="saiquImg" v-if="item.group_name == 'BFA赛区'" src="../../assets/fblc-BFA赛区.jpg">
            <img class="saiquImg" v-if="item.group_name == '国际赛区'" src="../../assets/fblc-国际赛区.jpg">
            <img class="saiquImg" v-if="item.group_name == '职教赛区'" src="../../assets/fblc-职教赛区.jpg">



            <div class="tefaLab">特发此证，以资鼓励。</div>

            <div class="timeLab">{{'颁发日期：' + award_create_time}}</div>
            <!-- <div class="timeLab">颁发日期：</div> -->


          </div>
        </div>
        <div id="showTotal" v-if="project_code == '21-22CEC'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer6">
            <img
                src="../../assets/cert_cec.jpg"
                class="model"
            />


            <!-- <img v-if="item.real_rank > 20" src="../../../assets/FBLA/cert_30.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../../assets/FBLA/cert_20.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 10" src="../../../assets/FBLA/cert_10.jpg" class="model"/> -->
            <div class="event" v-if="item.award_type == 'event rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event rank'">{{item.account.account_nameEN}}</div>


            <div class="event" v-if="item.award_type == 'personal total'">All Events in Economics</div>


            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank <= 10"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>
            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank > 10"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'personal total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'personal total'">{{ item.account.account_nameEN }}</div>



            <div class="event" v-if="item.award_type == 'team total'">Team Award</div>


            <div class="award" v-if="item.award_type == 'team total' && item.real_rank <= 10"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>
            <div class="award" v-if="item.award_type == 'team total' && item.real_rank > 10"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'team total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>


            <!-- <div class="event" v-if="item.award_type == 'team total'">{{ item.application_event }}</div> -->

            <!-- <div class="award_team_out" v-if="item.award_type == 'team total'"> {{showEvent(item.application_event) }}</div>

            <div class="student_team_out" v-if="item.award_type == 'team total'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>

            <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div> -->




          </div>
        </div>
        <div id="showTotal" v-if="project_code == '2022WEC'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer7">
            <img
                src="../../assets/cert_wec.jpg"
                class="model"
            />


            <!-- <img v-if="item.real_rank > 20" src="../../../assets/FBLA/cert_30.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../../assets/FBLA/cert_20.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 10" src="../../../assets/FBLA/cert_10.jpg" class="model"/> -->
            <div class="event" v-if="item.award_type == 'event rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event rank'">{{item.account.account_nameEN}}</div>


            <div class="event" v-if="item.award_type == 'personal total'">Economics (Aggregated) Award</div>


            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank <= 10"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>
            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank > 10"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'personal total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'personal total'">{{ item.account.account_nameEN }}</div>



            <div class="event" v-if="item.award_type == 'team total'">Team Average Performance</div>


            <div class="award" v-if="item.award_type == 'team total' && item.real_rank <= 10"> {{ item.award_show_lable }}</div>
            <div class="award" v-if="item.award_type == 'team total' && item.real_rank > 10"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'team total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>


            <div class="event" v-if="item.award_type == 'event team rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event team rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event team rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event team rank'">{{item.account.account_nameEN}}</div>



            <!-- <div class="event" v-if="item.award_type == 'team total'">{{ item.application_event }}</div> -->

            <!-- <div class="award_team_out" v-if="item.award_type == 'team total'"> {{showEvent(item.application_event) }}</div>

            <div class="student_team_out" v-if="item.award_type == 'team total'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>

            <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div> -->




          </div>
        </div>
        <div id="showTotal" v-if="project_code == '2023WEC' ">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer11">
            <img
                src="../../assets/cer_2023wec.jpg"
                class="model"
            />


            <!-- <img v-if="item.real_rank > 20" src="../../../assets/FBLA/cert_30.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../../assets/FBLA/cert_20.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 10" src="../../../assets/FBLA/cert_10.jpg" class="model"/> -->

            <!-- <div class="event" v-if="item.award_type == 'event rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event rank'"> {{item.award_lable}} </div>
            <div class="student" v-if="item.award_type == 'event rank'">
                <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event rank'">{{item.account.account_nameEN}}</div> -->


            <!-- <div class="event" v-if="item.award_type == 'personal total'">Economics (Aggregated) Award</div>


            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank <= 10"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>
            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank > 10"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'personal total'">
                <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'personal total'">{{ item.account.account_nameEN }}</div> -->



            <div class="event" v-if="item.award_type == 'team total'">Team Average Performance</div>

            <div class="award" v-if="item.award_type == 'team total'"> {{item.award_lable }}</div>

            <!-- <div class="award" v-if="item.award_type == 'team total' && item.real_rank <= 10"> {{ item.award_show_lable}}</div> -->
            <!-- <div class="award" v-if="item.award_type == 'team total' && item.real_rank > 10"> {{item.award_name }}</div> -->


            <div class="student" v-if="item.award_type == 'team total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>


            <!-- <div class="event" v-if="item.award_type == 'event expression'">{{item.application_event}}</div> -->

            <div class="award1" v-if="item.award_type == 'event expression' || item.award_type == 'event rank'"> {{item.award_lable}} </div>
            <div class="student1" v-if="item.award_type == 'event expression' || item.award_type == 'event rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school1" v-if="item.award_type == 'event expression' || item.award_type == 'event rank'">{{item.account.account_nameEN}}</div>



            <!-- <div class="event" v-if="item.award_type == 'team total'">{{ item.application_event }}</div> -->

            <!-- <div class="award_team_out" v-if="item.award_type == 'team total'"> {{showEvent(item.application_event) }}</div>

            <div class="student_team_out" v-if="item.award_type == 'team total'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>

            <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div> -->




          </div>
        </div>
        <div id="showTotal" v-if="project_code == '2024WEC'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer17">
            <img
                src="../../assets/cer_2024WEC.jpg"
                class="model"
            />

            <!-- <div class="event" >{{ item.application_event }}</div> -->


            <div class="event" v-if="item.award_type =='team total'">Team Average Performance </div>

            <div class="award" v-if="item.award_type !='team total'"  > {{ item.award_lable }}</div>
            <div class="award_team" v-if="item.award_type =='team total'"  > {{ item.award_lable }}</div>

            <div class="student" >
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type !='team total'" >{{ item.account.account_nameEN }}</div>
            <div class="school_team" v-if="item.award_type =='team total'" >{{ item.account.account_nameEN }}</div>

          </div>
        </div>
        <div id="showTotal" v-if="project_code == '2022IBO'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer8">
            <img
                src="../../assets/cer_ibo.jpg"
                class="model"
            />


            <!-- <img v-if="item.real_rank > 20" src="../../../assets/FBLA/cert_30.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../../assets/FBLA/cert_20.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 10" src="../../../assets/FBLA/cert_10.jpg" class="model"/> -->
            <div class="event" v-if="item.award_type == 'event rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event rank'">{{item.account.account_nameEN}}</div>


            <div class="event" v-if="item.award_type == 'personal total'">Business (Aggregated) Award</div>


            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank <= 20"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>
            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank > 20"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'personal total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'personal total'">{{ item.account.account_nameEN }}</div>



            <div class="event" v-if="item.award_type == 'team total'">Team Average Performance</div>


            <div class="award" v-if="item.award_type == 'team total' && item.real_rank <= 10"> {{ item.award_show_lable}}</div>
            <div class="award" v-if="item.award_type == 'team total' && item.real_rank > 10"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'team total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>


            <div class="event" v-if="item.award_type == 'event team rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event team rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event team rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event team rank'">{{item.account.account_nameEN}}</div>



            <!-- <div class="event" v-if="item.award_type == 'team total'">{{ item.application_event }}</div> -->

            <!-- <div class="award_team_out" v-if="item.award_type == 'team total'"> {{showEvent(item.application_event) }}</div>

            <div class="student_team_out" v-if="item.award_type == 'team total'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>

            <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div> -->




          </div>
        </div>
        <div id="showTotal" v-if="project_code == '2023IBO'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer8">
            <img
                src="../../assets/cer_ibo_2023.png"
                class="model"
            />


            <!-- <img v-if="item.real_rank > 20" src="../../../assets/FBLA/cert_30.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../../assets/FBLA/cert_20.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 10" src="../../../assets/FBLA/cert_10.jpg" class="model"/> -->
            <div class="event" v-if="item.award_type == 'event rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event rank'">{{item.account.account_nameEN}}</div>


            <div class="event" v-if="item.award_type == 'personal total'">Business (Aggregated) Award</div>


            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank <= 20"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>
            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank > 20"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'personal total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'personal total'">{{ item.account.account_nameEN }}</div>



            <div class="event" v-if="item.award_type == 'team total'">Team Average Performance</div>


            <div class="award" v-if="item.award_type == 'team total' && item.real_rank <= 10"> {{ item.award_show_lable}}</div>
            <div class="award" v-if="item.award_type == 'team total' && item.real_rank > 10"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'team total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>


            <div class="event" v-if="item.award_type == 'event team rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event team rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event team rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event team rank'">{{item.account.account_nameEN}}</div>



            <!-- <div class="event" v-if="item.award_type == 'team total'">{{ item.application_event }}</div> -->

            <!-- <div class="award_team_out" v-if="item.award_type == 'team total'"> {{showEvent(item.application_event) }}</div>

            <div class="student_team_out" v-if="item.award_type == 'team total'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>

            <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div> -->




          </div>
        </div>

        <div id="showTotal" v-if="project_code == '2024IBO'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer8">
            <img
                src="../../assets/cer_ibo_2024.png"
                class="model"
            />


            <!-- <img v-if="item.real_rank > 20" src="../../../assets/FBLA/cert_30.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../../assets/FBLA/cert_20.jpg" class="model"/> -->
            <!-- <img v-if="item.real_rank <= 10" src="../../../assets/FBLA/cert_10.jpg" class="model"/> -->
            <div class="event" v-if="item.award_type == 'event rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event rank'">{{item.account.account_nameEN}}</div>


            <div class="event" v-if="item.award_type == 'personal total'">Business (Aggregated) Award</div>


            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank <= 20"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>
            <div class="award" v-if="item.award_type == 'personal total' && item.real_rank > 20"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'personal total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'personal total'">{{ item.account.account_nameEN }}</div>



            <div class="event" v-if="item.award_type == 'team total'">Team Average Performance</div>


            <div class="award" v-if="item.award_type == 'team total' && item.real_rank <= 10"> {{ item.award_show_lable}}</div>
            <div class="award" v-if="item.award_type == 'team total' && item.real_rank > 10"> {{item.award_name }}</div>


            <div class="student" v-if="item.award_type == 'team total'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>


            <div class="event" v-if="item.award_type == 'event team rank'">{{item.application_event}}</div>
            <div class="award" v-if="item.award_type == 'event team rank'"> {{item.award_show_lable}} </div>
            <div class="student" v-if="item.award_type == 'event team rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event team rank'">{{item.account.account_nameEN}}</div>



            <!-- <div class="event" v-if="item.award_type == 'team total'">{{ item.application_event }}</div> -->

            <!-- <div class="award_team_out" v-if="item.award_type == 'team total'"> {{showEvent(item.application_event) }}</div>

            <div class="student_team_out" v-if="item.award_type == 'team total'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>

            <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div> -->




          </div>
        </div>


        <div id="showTotal" v-if="project_code == '22-23IPsyO'">
          <div v-for="(item,index) in event_awards" :key="index" :class="item.award_type =='personal total'? 'certContainer3': 'certContainer3'">
            <div v-if="item.award_type == 'personal total'">
              <img
                  src="../../assets/22_23IPsyO_Team.jpg"
                  class="model"
              />
              <div class="event" v-if="item.award_type == 'event rank'">{{item.application_event}}</div>
              <div class="award" v-if="item.award_type == 'event rank'"> {{item.award_show_lable}} </div>
              <div class="student" v-if="item.award_type == 'event rank'">
                <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
              </div>
              <div class="school" v-if="item.award_type == 'event rank'">{{item.account.account_nameEN}}</div>


              <!--              <div class="event" v-if="item.award_type == 'personal total'">Business (Aggregated) Award</div>-->


              <div class="award" v-if="item.award_type == 'personal total' && item.real_rank <= 20"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>
              <div class="award" v-if="item.award_type == 'personal total' && item.real_rank > 20"> {{item.award_name }}</div>


              <div class="student" v-if="item.award_type == 'personal total'">
                <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
              </div>
              <div class="school" v-if="item.award_type == 'personal total'">{{ item.account.account_nameEN }}</div>

              <!--item.country-->
              <div class="country" >{{ item.country }}</div>

              <div class="event" v-if="item.award_type == 'team total'">Team Average Performance</div>


              <div class="award" v-if="item.award_type == 'team total' && item.real_rank <= 10"> {{ item.award_show_lable}}</div>
              <div class="award" v-if="item.award_type == 'team total' && item.real_rank > 10"> {{item.award_name }}</div>


              <div class="student" v-if="item.award_type == 'team total'">
                <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
              </div>
              <div class="school" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>


              <div class="event" v-if="item.award_type == 'event team rank'">{{item.application_event}}</div>
              <div class="award" v-if="item.award_type == 'event team rank'"> {{item.award_show_lable}} </div>
              <div class="student" v-if="item.award_type == 'event team rank'">
                <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
              </div>
              <div class="school" v-if="item.award_type == 'event team rank'">{{item.account.account_nameEN}}</div>
            </div>
            <div v-else>
              <img
                  src="../../assets/22_23IPsyO_Team.jpg"
                  class="model"
              />

              <div class="country" >{{ item.country }}</div>
              <div class="event">Team Award</div>

              <div class="award" v-if=" item.real_rank <= 10"> {{ item.award_show_lable}}</div>
              <div class="award" v-if="item.real_rank > 10"> {{item.award_name }}</div>


              <div class="student">
                <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
              </div>
              <div class="school" >{{ item.account.account_nameEN }}</div>

            </div>



            <!-- <div class="event" v-if="item.award_type == 'team total'">{{ item.application_event }}</div> -->

            <!-- <div class="award_team_out" v-if="item.award_type == 'team total'"> {{showEvent(item.application_event) }}</div>

            <div class="student_team_out" v-if="item.award_type == 'team total'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>

            <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div> -->




          </div>
        </div>




        <div id="showTotal" v-if="project_code == '2022AM_InternationalFinal'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer9">
            <img
                src="../../assets/cer_2022am.jpg"
                class="model"
            />

            <div class="event" v-if="item.award_type == 'event rank'">{{ `${showEvent(item.application_event)}` }}</div>


            <div class="award" v-if="item.award_type == 'event rank'"> {{ item.award_name }}</div>

            <div class="student" v-if="item.award_type == 'event rank'">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" v-if="item.award_type == 'event rank'">{{ item.account.account_nameEN }}</div>



            <div class="award_rank" v-if="item.award_type == 'event expression'"> {{`${showEvent(item.application_event)}`   }}</div>

            <div class="student_rank" v-if="item.award_type == 'event expression'">
              <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_rank" v-if="item.award_type == 'event expression'">{{ item.account.account_nameEN }}</div>


            <div class="info_rank" v-if="item.award_type == 'event expression'">Has successfully completed examinations with strong academic <br> rigor in the subject of</div>

            <!-- <div class="event" v-if="item.award_type == 'event rank'">{{ item.application_event }}</div> -->






          </div>
        </div>

        <div id="showTotal" v-if="project_code == '22-23CEO_Application'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer12">
            <!-- <img  src="../../../assets/FBLA/23fbla_bg1.jpg" class="model"/> -->
            <img src="../../assets/22-23ceo_app.png" class="model"/>
            <!-- <img v-if="item.award_type =='event indi rank' || item.award_type =='event team rank' || item.award_type =='personal total' " src="../../../assets/FBLA/cer_gis.jpg" class="model"/> -->
            <!-- <img v-if="item.award_type =='event indi line grade' || item.award_type =='event team line grade'" src="../../../assets/FBLA/cer_gis.jpg" class="model"/> -->
            <!-- <div class="event">{{item.application_event}}</div> -->
            <!-- <div class="award" v-if="item.award_lable_type == 'rank'">National {{`${item.real_rank}${rank(item.real_rank)}`}} Place</div> -->
            <!-- <div class="award" v-if="item.award_lable_type == 'percent'">National {{item.award_show_lable}}</div> -->

            <div class="award" >{{item.award_lable}}</div>

            <div class="event" >{{item.application_event}}</div>

            <div class="student">
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school">{{item.account.account_nameEN}}</div>
          </div>
        </div>

        <div id="showTotal" v-if="project_code == '22-23AM_National'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer13">
            <img
                src="../../assets/22-23AM_National.jpg"
                class="model"
            />

            <div class="event" >{{ `${showEvent(item.application_event)}` }}</div>


            <div class="award" > {{ item.award_lable }}</div>

            <div class="student" >
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" >{{ item.account.account_nameEN }}</div>




            <!-- <div class="award_team_out" v-if="item.award_type == 'team total'"> {{showEvent(item.application_event) }}</div>

            <div class="student_team_out" v-if="item.award_type == 'team total'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>

            <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div>


            <div class="award_team" v-if="item.award_type == 'event expression'"> {{ item.manually_add_award_lable}}</div>

            <div class="student_team" v-if="item.award_type == 'event expression'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_team" v-if="item.award_type == 'event expression'">{{ item.account.account_nameEN }}</div>



            <div class="award_rank" v-if="item.award_type == 'event complete'"> {{`${showEvent(item.application_event)}`   }}</div>

            <div class="student_rank" v-if="item.award_type == 'event complete'">
                <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school_rank" v-if="item.award_type == 'event complete'">{{ item.account.account_nameEN }}</div>


            <div class="info_rank" v-if="item.award_type == 'event complete'">Has successfully completed 12-hour intense college-level studies and examinations with strong academic rigor <br> in the subject of</div> -->






          </div>
        </div>


        <div id="showTotal" v-if="project_code == '2023WEC_Continental'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer16">
            <img
                src="../../assets/cer_2023WEC_Continental.png"
                class="model"
            />

            <div class="event" >{{ item.application_event }}</div>


            <div class="award" > {{ item.award_lable }}</div>

            <div class="student" >
              <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school" >{{ item.account.account_nameEN }}</div>



          </div>
        </div>


        <div id="showIpsyoTotal" v-if="project_code == '2024IPsyO'">
          <div v-for="(item,index) in event_awards" :key="index" class="certContainer18" style="margin-bottom:10px;">

            <img src="../../assets/cer_2024ipsyo.jpg" class="model_p"/>
            <div class="name">{{upfirstName(item.student.student_givenName_pinyin) + ' ' + upfirstName(item.student.student_lastName_pinyin) }}</div>

            <div class="content">{{item.account_name}}</div>

            <div class="country">{{item.award_show_lable}}</div>

          

          </div>
        </div>

      </el-tab-pane>
      <!-- <el-tab-pane label="证书2">
          <el-button type="primary" @click="exportPDF('showTotal')" size="medium">导出证书</el-button>
          <div id="showTotalScreen">
              <div v-for="(item,index) in total_awards" :key="index" class="certContainer1">
                  <img src="../../../assets/FBLA/tv_10.jpg" class="model"/>
                  <div class="event">Banking & Financial Systems</div>
                  <div class="award">National First Prize</div>
                  <div class="student">
                      <span class="name">Jielun Zhou</span>
                      <span class="name">Jielun Zhou</span>
                      <span class="name">Jielun Zhou</span>
                  </div>
                  <div class="school">Jiangsu Nanjing NO.179 Example School Guangxi Nanning Science</div>
              </div>
          </div>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="项目奖">
          <el-button type="primary" @click="exportPDF('showEvent')" size="medium">导出证书</el-button>
          <div id="showEvent">
              <div v-for="item in event_awards" :key="item._id.$id" class="container">
                  <img src="../../../assets/award.jpg" class="model"/>
                  <div class="student">{{upfirstName(item.student.student_givenName_pinyin)+' '+upfirstName(item.student.student_lastName_pinyin)}}</div>
                  <div class="account">{{item.account?item.account.account_nameEN:''}}</div>
                  <div>
                      <div class="event">{{item.application_event}} - {{award_type_label_arr[item.award_type]}}</div>

                      <div class="award">National {{item.real_rank}}{{rank(item.real_rank)}} Place</div>
                  </div>
              </div>
          </div>
      </el-tab-pane>
      <el-tab-pane label="参赛证明">
          <el-button type="primary" @click="exportPDF('showComplete')" size="medium">导出证书</el-button>
          <div id="showComplete">
              <div v-for="item in completes" :key="item._id.$id" class="container">
                  <img src="../../../assets/complete.jpg" class="model"/>
                  <div class="student">{{upfirstName(item.student.student_givenName_pinyin)+' '+upfirstName(item.student.student_lastName_pinyin)}}</div>
                  <div class="account">
                      <div>{{item.account?item.account.account_nameEN:''}}</div>
                      <div style="margin-top:30px;">has completed all competitive events in Economics, Financial Literacy and Business Case</div>
                  </div>
              </div>
          </div>
      </el-tab-pane> -->
      <el-tab-pane :label="$t('m.mychengjidan')" v-if="project_code != '2024WEC' ">
        <el-button type="primary" @click="exportPDF('showTranscript')" size="medium" style="margin:5px;">{{$t('m.mydaochupdf')}}</el-button>

        <div id="showTranscript" v-if="project_code == '21-22CEC'">
          <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer1" style="margin-bottom:10px;">

            <img src="../../assets/transcript_cec.jpg" class="model_p"/>
            <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) }}</div>

            <div class="content">{{item.account_info.account_nameEN}}</div>




            <div  v-for="score in item.score_info" :key="score._id.$id">
              <div v-if="score.application_event == 'Thinking and Innovation'" class="teamScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

              <div v-if="score.application_event == 'Fundamentals'" class="fScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>


              <div v-if="score.application_event == 'Deep Comprehension'" class="dcScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

            </div>


          </div>
        </div>
        <div id="showTranscript" v-if="project_code == '2022WEC'">
          <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer2" style="margin-bottom:10px;">

            <img src="../../assets/transcript_wec.jpg" class="model_p"/>
            <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) }}</div>

            <div class="content">{{item.account_info.account_nameEN}}</div>




            <div  v-for="score in item.score_info" :key="score._id.$id">
              <div v-if="score.application_event == 'Thinking and Innovation'" class="teamScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

              <div v-if="score.application_event == 'Fundamentals'" class="fScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>


              <div v-if="score.application_event == 'Deep Comprehension'" class="dcScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

            </div>


          </div>
        </div>
        <div id="showTranscript" v-if="project_code == '2022IBO'">
          <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer3" style="margin-bottom:10px;">

            <img src="../../assets/transcript_ibo.jpg" class="model_p"/>
            <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) }}</div>

            <div class="content">{{item.account_info.account_nameEN}}</div>




            <div  v-for="score in item.score_info" :key="score._id.$id">
              <div v-if="score.application_event == 'Objective Test'" class="teamScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

              <div v-if="score.application_event == 'Interactive Objective Case'" class="fScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>


              <div v-if="score.application_event == 'Open Case Analysis'" class="dcScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

            </div>


          </div>
        </div>
        <div id="showTranscript" v-if="project_code == '2023IBO'">
          <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer3" style="margin-bottom:10px;">

            <img src="../../assets/transcript_ibo_2023.png" class="model_p"/>
            <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) }}</div>

            <div class="content">{{item.account_info.account_nameEN}}</div>




            <div  v-for="score in item.score_info" :key="score._id.$id">
              <div v-if="score.application_event == 'Objective Test'" class="teamScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

              <div v-if="score.application_event == 'Interactive Objective Case'" class="fScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>


              <div v-if="score.application_event == 'Open Case Analysis'" class="dcScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

            </div>


          </div>
        </div>
        <div id="showTranscript" v-if="project_code == '2024IBO'">
          <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer3" style="margin-bottom:10px;">

            <img src="../../assets/transcript_ibo_2024.png" class="model_p"/>
            <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) }}</div>

            <div class="content">{{item.account_info.account_nameEN}}</div>




            <div  v-for="score in item.score_info" :key="score._id.$id">
              <div v-if="score.application_event == 'Objective Test'" class="teamScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

              <div v-if="score.application_event == 'Interactive Objective Case'" class="fScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>


              <div v-if="score.application_event == 'Open Case Analysis'" class="dcScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

            </div>

            <div v-if="item.score_info.length <= 2" class="dcScore" >

              <div style="margin-top:5px"><span>{{ '-'}}</span> / {{ '100'}}</div>
            </div>



          </div>
        </div>


        <div id="showTranscript" v-if="project_code == '22-23IPsyO'">
          <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer4" style="margin-bottom:10px;">

            <img src="../../assets/transcript_ipsyo.jpg" class="model_p"/>
            <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) }}</div>

            <div class="content">{{item.account_info.account_nameEN}}</div>

            <div class="country">{{item.country}}</div>

            <div style="display:flex;flex-direction: row;position: absolute;top: 693px;left: 170px">
              <div  v-for="(score,index) in item.score_info" :key="score._id.$id" >
                <div style="text-align: center;line-height: 50px;width: 100px;height: 50px;">
                  {{getRightScore(item.score_info,index)}}
                </div>
              </div>
              <div style="margin-left: 10px;text-align: center;line-height: 50px;width: 100px;height: 50px;">
                {{formatTotalScore(item.score_info)}}
              </div>
            </div>

          </div>
        </div>

        <div id="showTranscript" v-if="project_code == '2024IPsyO'">
          <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer4" style="margin-bottom:10px;">

            <img src="../../assets/transcript_2024ipsyo.jpg" class="model_p"/>
            <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) }}</div>

            <div class="content">{{item.score_info[0].account_name}}</div>

            <!-- <div class="country">{{item.country}}</div> -->

            <div style="display:flex;flex-direction: row;position: absolute;top: 693px;left: 170px;">

              <div style="text-align: center;line-height: 50px;width: 135px;height: 50px;font-size: 25px;">
                {{item.score_info[0].old_total_score}}
              </div>

              <div style="text-align: center;line-height: 50px;width: 170px;height: 50px;font-size: 25px;">
                {{item.score_info[0].short_answer_score}}
              </div>

              <div style="text-align: center;line-height: 50px;width: 150px;height: 50px;font-size: 25px;">
                {{item.score_info[0].application_score}}
              </div>

              <div style="text-align: center;line-height: 50px;width: 90px;height: 50px;font-size: 25px;">
                {{item.score_info[0].total_score}}
              </div>

              <!-- <div  v-for="(score,index) in item.score_info" :key="score._id.$id" >
                <div style="text-align: center;line-height: 50px;width: 100px;height: 50px;">
                  {{getRightScore(item.score_info,index)}}
                </div>
              </div>
              <div style="margin-left: 10px;text-align: center;line-height: 50px;width: 100px;height: 50px;">
                {{formatTotalScore(item.score_info)}}
              </div> -->
            </div>

          </div>
        </div>

        <div id="showTranscript" v-if="project_code == '2023WEC_Continental' ">
          <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer2" style="margin-bottom:10px;">

            <img src="../../assets/transcript_wec_22_23AM_Asian.png" class="model_p"/>
            <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) }}</div>

            <div class="content">{{item.account_info.account_nameEN}}</div>




            <div  v-for="score in item.score_info" :key="score._id.$id">
              <div v-if="score.application_event == 'Thinking and Innovation'" class="teamScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

              <div v-if="score.application_event == 'Fundamentals'" class="fScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>


              <div v-if="score.application_event == 'Deep Comprehension'" class="dcScore" >

                <div style="margin-top:5px"><span>{{score.total_score}}</span> / {{score.full_score}}</div>
              </div>

            </div>


          </div>
        </div>
        <!-- <div id="showTranscript">
            <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer" style="margin-bottom:10px;">
                <img src="../../assets/参赛证明(2).jpg" class="model_p"/>
                <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) + ' ' + item.student_info.student_lastName + ' ' + item.student_info.student_givenName}}</div>
                <div class="info">
                    <div>
                        <span class="tag">邮箱: </span>
                        <span class="content">{{item.student_info.student_email}}</span> |
                        <span class="tag">性别: </span>
                        <span class="content">{{item.student_info.student_gender == '男'? '男' : '女'}}</span> |
                        <span class="tag">预计毕业年份: </span>
                        <span class="content">{{item.student_info.student_graduation}}</span>
                    </div>
                    <div style="margin-top:6px">
                        <span class="tag">学校: </span>
                        <span class="content">{{item.account_info.account_name}}</span>
                    </div>
                </div>
                <div class="score" >
                    <div  v-for="score in item.score_info" :key="score._id.$id">
                        <div v-if="score.score_type == 'team'" style="margin-bottom:20px;" >
                            <div class="title">{{score.application_event + ' - ' + score.mode }}</div>
                            <div style="margin-top:5px">Score:<span>{{score.total_score}}</span>/{{score.full_score}}  <span style="margin-left:10px;"></span>Percentile: Top <span>{{score.percentile}}</span></div>

                            <div style="margin-top:5px"># of Competitors: {{score.competitor_count}}</div>
                        </div>
                    </div>
                </div>
                <div class="score1" >
                    <div  v-for="score in item.score_info" :key="score._id.$id">
                        <div  v-if="score.score_type == 'personal'" style="margin-bottom:20px;">
                            <div class="title">{{score.application_event + ' - ' + '客观笔试'}}</div>
                            <div style="margin-top:5px">分数:<span>{{score.total_score}}</span>/{{score.full_score}} ></div>
                            <div style="margin-top:5px"># of Competitors：{{score.competitor_count}}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getStudentAwards,getStudentTranscripts} from '../../api/index.js'
import {getUserId,getProjectCode} from '../../utils/store'
import {formatDate} from '../../utils/date'

export default {
  name: 'awardfbla',
  data () {
    return {
      project_code: '',
      total_awards: [],
      event_awards: [],
      completes: [],
      transcripts:[],
      type:'all',
      id:'',
      award_create_time:'2022年6月7日',
      award_type_label_arr:{
        'personal total':'Individual Total Score Rank',
        'event rank':'Individual Event Rank',
        'event team rank':'Team Event Rank'
      },
      direction:1

    }
  },
  mounted () {

    this.project_code = this.$route.query.project_codeStr
    this.id = this.$route.query.id

    this.fetchData()
  },
  methods: {

    getRightScore(score_info,index){
      let event = 'Session '+ this.formatIndexToEng(index)
      for (let i=0;i<score_info.length;i++){
        let score = score_info[i]
        if(score.application_event == event) {
          return score.total_score
        }
      }
    },
    formatIndexToEng(index){
      switch (index){
        case  0:
          return 'One'
        case  1:
          return 'Two'
        case  2:
          return 'Three'
        case  3:
          return 'Four'
      }
    },

    formatTotalScore(score_info){
      let total = 0
      for (let i=0;i<score_info.length;i++){
        total = total +  score_info[i].total_score
      }
      return total
    },

    fetchData () {

      // console.log(1111,getProjectCode())

      getStudentAwards(this.project_code,getUserId()).then(res => {
        if (res.data.code === 0) {
          this.total_awards = res.data.data.filter(
              (item) => item.award_type === 'personal total'
          )

          if (this.project_code === '21-22AM_Final' || this.project_code === '2022AM_InternationalFinal' || this.project_code === '21-22CEC' || this.project_code === '2022WEC' || this.project_code === '2022IBO' || this.project_code === '2023WEC'
          ){
            this.event_awards = res.data.data.filter(
                (item) => item.award_type === 'event rank' || item.award_type === 'event team rank' || item.award_type === 'team total' || item.award_type === 'personal total'  || item.award_type === 'event expression'|| item.award_type === 'event complete'
            )
          }else{
            this.event_awards = res.data.data.filter(
                (item) => item.award_type === 'event rank' || item.award_type === 'event team rank' || item.award_type === 'team total' || item.award_type === 'personal total'|| item.award_type === 'event expression'
            )
          }

          // this.event_awards = res.data.data.filter(
          //     (item) => item.award_type === 'event rank' || item.award_type === 'event team rank'
          // )


          if (this.event_awards.length <= 0){

            this.$message.error('You do not have any certificates available.')

          }

        }
      })
      //   getCompletes().then(res => {
      //     if (res.data.code === 0) {
      //       this.completes = res.data.data
      //     }
      //   })
      getStudentTranscripts(this.project_code,getUserId()).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          this.transcripts = res.data.data
        };

      })


    },
    formatCreateTime(create_time) {
      var time = JSON.parse(create_time) * 1000
      var temp_date = new Date(time)
      return formatDate(temp_date, 'yyyy/MM/dd')
    },
    rank (number) {
      var tail = number.toString().charAt(number.toString().length-1,1)
      if (tail === '1' && number !== 11) {
        return "st";
      } else if (tail === '2' && number !== 12) {
        return "nd";
      } else if (tail === '3' && number !== 13) {
        return "rd";
      } else {
        return "th";
      }
    },
    upfirstName (name) {


      if (name == 'João Pedro'){

        return name
      }

      if (name == 'AGYEN-FREMPONG'){
        return name
      }

      return name.toLowerCase().replace(/\b([\w|‘]+)\b/g, function(word) {
        //return word.slice(0, 1).toUpperCase() + word.slice(1);
        return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
      });

      if (name) {

        if (name.indexOf(" ") == -1){
          const transferName = name.toLowerCase()
          return transferName.charAt(0).toUpperCase() + transferName.slice(1)
        }else{
          var nameList = name.split(' ');

          var finishName = ''
          for (var i = 0; i < nameList.length; i++) {
            var a = nameList[i];

            const transferName = a.toLowerCase()
            finishName = finishName + ' '+ transferName.charAt(0).toUpperCase() + transferName.slice(1)
          }

          return finishName
        }

      }else{
        return name
      }

    },
    showEvent(event){

      if (event == 'Artithon'){
        return 'Artithon - Academic Marathon in Art'
      }

      if (event == 'Biothon'){
        return 'Biothon - Academic Marathon in Biology'
      }

      if (event == 'Chemithon'){
        return 'Chemithon - Academic Marathon in Chemistry'
      }
      if (event == 'Econthon'){
        return 'Econthon - Academic Marathon in Economics'
      }
      if (event == 'Geothon'){
        return 'Geothon - Academic Marathon in Geography'
      }
      if (event == 'Historithon'){
        return 'Historithon - Academic Marathon in History'
      }
      if (event == 'Mathethon'){
        return 'Mathethon - Academic Marathon in Mathematics'
      }

      if (event == 'Physithon'){
        return 'Physithon - Academic Marathon in Physics'
      }

      if (event == 'Psychothon'){
        return 'Psychothon - Academic Marathon in Psychology'
      }


    },
    exportPDF (id) {


      var style = document.createElement('style');
      //
      if (id == 'showTotal'){
        // style.innerHTML = "@page{size:landscape 1528px 1080px;}";
        style.innerHTML = "@page{size:A4 landscape;}";
      }else{
        // style.innerHTML = "@page{size:portrait 2480px 3508px;margin: auto 0mm}";
        style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
        document.getElementsByTagName("body")[0].style.zoom = 1
      }

      window.document.head.appendChild(style)

      const printData = document.getElementById(id).innerHTML


      this.PageSetup_Null();//把页眉页脚设置为空


      window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
      window.print() // 开始打印
    },
    PageSetup_Null(){
      var HKEY_Root,HKEY_Path,HKEY_Key;

      HKEY_Root="HKEY_CURRENT_USER";

      HKEY_Path="\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

      try{
        var Wsh=new ActiveXObject("WScript.Shell");

        HKEY_Key="header";

        Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

        HKEY_Key="footer";

        Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

      }catch(e){}

    }

  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'aleo-bold';
  src: url('../../assets/font/aleo-bold.ttf');
}
@font-face {
  font-family: 'Aleo-Bold-2';
  src: url('../../assets/font/Aleo-Bold-2.otf');
}
@font-face {
  font-family: 'Aleo-Italic-4';
  src: url('../../assets/font/Aleo-Italic-4.otf');
}
@font-face {
  font-family: 'Aleo-Light-5';
  src: url('../../assets/font/Aleo-Light-5.otf');
}
@font-face {
  font-family: 'Aleo-Regular-7';
  src: url('../../assets/font/Aleo-Regular-7.otf');
}

@font-face {
  font-family: 'Bell-MT';
  src: url('../../assets/fonts/Bell-MT.ttf');
}

@font-face {
  font-family: 'BellMT-Bold';
  src: url('../../assets/fonts/BellMT-Bold.otf');
}

.certContainer {
  position: relative;
  color: rgb(42, 70, 154);
  .model {
    width: 297mm;
    height: 200.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 430px;
    left: 116px;
    font-weight: 500;
  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 525px;
    left: 250px;
    font-weight: 500;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 300px;
    left: 116px;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    .name {
      margin-right: 40px;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 260px;
    left: 116px;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    background-color:  red;
  }
}
.certContainer1{
  position: relative;
  color: white;
  .model{
    width: 365.28mm;
    height: 205.47mm;
    z-index: -10;
  }
  .event{
    position: absolute;
    font-size: 70px;
    top: 100px;
    left: 86px;
    font-weight: 300;
    width: 600px;
  }
  .award{
    position: absolute;
    font-size: 90px;
    top: 300px;
    left: 86px;
    font-weight: 600;
  }
  .student{
    position: absolute;
    width: 600px;
    top: 460px;
    left: 86px;
    font-size: 40px;
    font-style: italic;
    font-weight: 300;
    .name{
      margin-right: 40px;
      word-wrap: none;
    }
  }
  .school{
    position: absolute;
    top: 570px;
    left: 86px;
    font-size: 30px;
    font-weight: 300;
  }
}

.certContainer2{
  position: relative;
  color: white;
  .model{
    width: 210.1mm;
    height: 297.127083mm;
    z-index: -10;
  }
  .award{
    font-family: Bell-MT;
    text-align: center;
    width: 210.1mm;
    color: #86022a;
    position: absolute;
    font-size: 40px;
    top: 700px;
    font-weight: 600;
  }
  .country{
    font-family: Bell-MT;
    width: 210.1mm;
    text-align: center;
    color: black;
    position: absolute;
    top: 580px;
    font-size: 30px;
    font-weight: 600;
  }
  .student{
    font-family: Bell-MT;
    width: 210.1mm;
    text-align: center;
    color: black;
    position: absolute;
    text-align: center;
    top: 460px;
    font-size: 40px;
    font-weight: 600;
    .name{
      word-wrap: none;
    }
  }
  .school{
    font-family: Bell-MT;
    width: 210.1mm;
    text-align: center;
    color: black;
    position: absolute;
    top: 530px;
    font-size: 30px;
    font-weight: 600;
  }
}
.certContainer3{
  position: relative;
  color: white;
  .model{
    width: 297mm;
    height: 200.7mm;
    z-index: -10;
  }
  .event{
    font-family: Bell-MT;
    text-align: center;
    width: 297mm;
    position: absolute;
    font-size: 25px;
    color: black;
    top: 410px;
    font-weight: 600;
  }
  .award{
    text-align: center;
    width: 297mm;
    color: #86022a;
    position: absolute;
    font-size: 40px;
    top: 490px;
    font-weight: 600;
    font-family: Bell-MT;
  }
  .student{
    font-family: Bell-MT;
    width: 297mm;
    text-align: center;
    color: black;
    position: absolute;
    text-align: center;
    top: 280px;
    font-size: 40px;
    font-weight: 600;
    .name{
      word-wrap: none;
    }
  }
  .school{
    width: 297mm;
    text-align: center;
    color: black;
    position: absolute;
    top: 350px;
    font-size: 25px;
    font-weight: 600;
    font-family: Bell-MT;
  }
}


.certContainer6{
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;

  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 530px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_team_out{
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out{
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out{
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team{
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 345px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 420px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank{
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}
.certContainer7{
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 392px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;

  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 440px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_team_out{
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out{
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out{
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team{
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 295px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank{
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}
.certContainer8{
  position: relative;
  color: black;
  font-family: 'BellMT-Bold';

  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;

  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 520px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_team_out{
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out{
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out{
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team{
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 370px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // font-family: 'Bell-MT';

    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 426px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank{
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}
.certContainer9{
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 422px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;

  }
  .info_rank{
    position: absolute;
    top: 405px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .student_rank {
    position: absolute;
    top: 310px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student {
    position: absolute;
    top: 320px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_rank {
    position: absolute;
    top: 365px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }

}
.certContainer11{
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 392px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;

  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 440px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award1 {
    position: absolute;
    font-size: 28px;
    top: 420px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .student1 {
    position: absolute;
    top: 315px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school1 {
    position: absolute;
    top: 375px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_team_out{
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out{
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out{
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team{
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 295px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }

  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank{
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}
.certContainer12{
  position: relative;
  color: black;
  .model{
    width: 297mm;
    height: 208.7mm;
  }
  .event{
    position: absolute;
    top: 440px;
    width: 297mm;

    text-align: center;
    font-size: 23px;
    font-weight: 550;
    color: rgb(234,118,59);

  }
  .award{
    position: absolute;
    // font-size: 48px;
    top: 480px;
    // left: 86px;
    width: 297mm;
    // font-weight: 600;
    text-align: center;
    font-size: 23px;
    font-weight: 550;
    text-align: center;
    color: rgb(234,118,59);
    // font-family: 'aleo-bold';
    white-space: pre;
  }
  .award1{
    position: absolute;
    font-size: 28px;
    top: 430px;
    left: 20mm;
    // right: 36px;
    // background-color: rebeccapurple;
    width: 257mm;
    font-weight: 600;
    text-align: center;
    // font-family: 'aleo-bold';
    white-space: pre;
  }
  .student{
    position: absolute;
    top: 350px;
    // left: 86px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    // font-weight: 300;
    text-align: center;

    font-size: 36px;
    font-weight:550;

    // font-family: 'aleo-bold';
    color: rgb(230,118,50);


  }
  .school{
    position: absolute;
    top: 405px;
    width: 297mm;
    // left: 86px;
    color: rgb(234,118,59);
    font-size: 18px;
    // font-weight: 300;
    text-align: center;

    // font-family: 'Aleo-Regular-7';

  }
}
.certContainer13{
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 383px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;

  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 430px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_team_out{
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out{
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out{
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team{
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank{
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}
.certContainer16{
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 200.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 395px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;

  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 440px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_team_out{
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out{
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out{
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team{
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank{
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}

.certContainer17{
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 200.7mm;
  }
  .event {
    position: absolute;
    font-size: 25px;
    top: 390px;
    // left: 116px;
    text-align: center;
    font-weight: 450;
    width: 297mm;

  }
 
  .award {
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_team {
    position: absolute;
    font-size: 28px;
    top: 430px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  
  .student {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  
  .school {
    position: absolute;
    top: 360px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 450;
  }
  .school_team {
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 450;
  }
 
  
}

.certContainer18{
  position: relative;
  margin-bottom:-4px;
  font-family: 'BellMT-Bold';
  .model_p{
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name{
    font-family: Bell-MT;
    position: absolute;
    top: 445px;
    font-size: 40px;
    font-weight: 600;
    width: 210mm;
    align-content: center;
    text-align: center;
  }
  .country{
    font-family: Bell-MT;
    position: absolute;
    top: 550px;
    font-size: 35px;
    font-weight: 600;
    width: 210mm;
    align-content: center;
    text-align: center;
  }

  .content{
    font-family: Bell-MT;
    position: absolute;
    top: 640px;
    font-size: 30px;
    left: 30mm;
    font-weight: 600;
    width: 150mm;
    align-content: center;
    text-align: center;
  }
  .fScore{
    color: black;
    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 93mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .dcScore{
    color: black;
    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 145mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .teamScore{
    font-family: Bell-MT;
    color: black;
    position: absolute;
    top: 458px;
    font-size: 20px;
    left: 39mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;

  }
  .info{
    color: black;
    position: absolute;
    top:384px;
    left: 38px;
    font-size: 14px;
    .tag{
      font-family: 'Aleo-Bold-2';
    }

  }
  .score{
    color: black;
    position: absolute;
    top: 500px;
    width:320px;

    left:55px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
  .score1{
    color: black;
    position: absolute;
    top: 500px;
    width:320px;
    left:434px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
}

.transcriptContainer{
  position: relative;
  margin-bottom:-4px;
  .model_p{
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name{
    position: absolute;
    top: 326px;
    left: 38px;
    font-size: 30px;

  }
  .info{
    position: absolute;
    top:384px;
    left: 38px;
    font-size: 15px;
    .tag{
      font-family: 'Aleo-Bold-2';
    }
    .content{
      font-family: 'Aleo-Regular-7';
    }
  }
  .score{
    position: absolute;
    top: 500px;
    width:320px;

    left:55px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
  .score1{
    position: absolute;
    top: 500px;
    width:320px;
    left:434px;
    font-size: 15px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 17px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
}
.transcriptContainer1{
  position: relative;
  margin-bottom:-4px;
  .model_p{
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name{
    position: absolute;
    top: 450px;
    // left: 38px;
    font-size: 40px;
    font-weight: 500;
    width: 210mm;
    align-content: center;
    text-align: center;
    // background-color: red;
  }

  .content{
    position: absolute;
    top: 540px;
    font-size: 30px;
    left: 30mm;
    font-weight: 400;
    width: 150mm;
    align-content: center;
    text-align: center;
    // background-color: rebeccapurple;
    // font-family: 'Aleo-Regular-7';
  }
  .fScore{

    position: absolute;
    top: 754px;
    font-size: 20px;
    left: 85mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .dcScore{

    position: absolute;
    top: 792px;
    font-size: 20px;
    left: 100mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .teamScore{

    position: absolute;
    top: 830px;
    font-size: 20px;
    left: 125mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;

  }
  .info{
    position: absolute;
    top:384px;
    left: 38px;
    font-size: 14px;
    .tag{
      font-family: 'Aleo-Bold-2';
    }

  }
  .score{
    position: absolute;
    top: 500px;
    width:320px;

    left:55px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
  .score1{
    position: absolute;
    top: 500px;
    width:320px;
    left:434px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
}
.transcriptContainer2{
  position: relative;
  margin-bottom:-4px;
  .model_p{
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name{
    position: absolute;
    top: 380px;
    // left: 38px;
    font-size: 40px;
    font-weight: 500;
    width: 210mm;
    align-content: center;
    text-align: center;
    // background-color: red;
  }

  .content{
    position: absolute;
    top: 450px;
    font-size: 30px;
    left: 30mm;
    font-weight: 400;
    width: 150mm;
    align-content: center;
    text-align: center;
    // background-color: rebeccapurple;
    // font-family: 'Aleo-Regular-7';
  }
  .fScore{

    position: absolute;
    top: 613px;
    font-size: 20px;
    left: 87mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .dcScore{

    position: absolute;
    top: 645px;
    font-size: 20px;
    left: 103mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .teamScore{

    position: absolute;
    top: 678px;
    font-size: 20px;
    left: 127mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;

  }
  .info{
    position: absolute;
    top:384px;
    left: 38px;
    font-size: 14px;
    .tag{
      font-family: 'Aleo-Bold-2';
    }

  }
  .score{
    position: absolute;
    top: 500px;
    width:320px;

    left:55px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
  .score1{
    position: absolute;
    top: 500px;
    width:320px;
    left:434px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
}
.transcriptContainer3{
  position: relative;
  margin-bottom:-4px;
  font-family: 'BellMT-Bold';
  .model_p{
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name{
    position: absolute;
    top: 495px;
    // left: 38px;
    font-size: 40px;
    font-weight: 500;
    width: 210mm;
    align-content: center;
    text-align: center;
    // background-color: red;
  }

  .content{
    position: absolute;
    top: 565px;
    font-size: 30px;
    left: 30mm;
    font-weight: 400;
    width: 150mm;
    align-content: center;
    text-align: center;
    // background-color: rebeccapurple;
    // font-family: 'Aleo-Regular-7';
  }
  .fScore{

    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 93mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .dcScore{

    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 145mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .teamScore{

    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 39mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;

  }
  .info{
    position: absolute;
    top:384px;
    left: 38px;
    font-size: 14px;
    .tag{
      font-family: 'Aleo-Bold-2';
    }

  }
  .score{
    position: absolute;
    top: 500px;
    width:320px;

    left:55px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
  .score1{
    position: absolute;
    top: 500px;
    width:320px;
    left:434px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
}


.transcriptContainer4{
  position: relative;
  margin-bottom:-4px;
  font-family: 'BellMT-Bold';
  .model_p{
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name{
    font-family: Bell-MT;
    position: absolute;
    top: 345px;
    font-size: 40px;
    font-weight: 600;
    width: 210mm;
    align-content: center;
    text-align: center;
  }
  .country{
    font-family: Bell-MT;
    position: absolute;
    top: 410px;
    font-size: 25px;
    font-weight: 600;
    width: 210mm;
    align-content: center;
    text-align: center;
  }

  .content{
    font-family: Bell-MT;
    position: absolute;
    top: 440px;
    font-size: 30px;
    left: 30mm;
    font-weight: 600;
    width: 150mm;
    align-content: center;
    text-align: center;
  }
  .fScore{
    color: black;
    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 93mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .dcScore{
    color: black;
    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 145mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .teamScore{
    font-family: Bell-MT;
    color: black;
    position: absolute;
    top: 458px;
    font-size: 20px;
    left: 39mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;

  }
  .info{
    color: black;
    position: absolute;
    top:384px;
    left: 38px;
    font-size: 14px;
    .tag{
      font-family: 'Aleo-Bold-2';
    }

  }
  .score{
    color: black;
    position: absolute;
    top: 500px;
    width:320px;

    left:55px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
  .score1{
    color: black;
    position: absolute;
    top: 500px;
    width:320px;
    left:434px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
}
</style>
